import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Classify from '../components/icon/Classify';
import EEA from '../components/icon/EEA';
import DAA from '../components/icon/DAA';
import Splitting from '../components/icon/Splitting';
import Verify from '../components/icon/Verify';

import { ReactComponent as ClassifyImg } from '../img/services/Klassifizieren_img.svg';
import { ReactComponent as SplittingImg } from '../img/services/Dokumente_teilen_img.svg';
import { ReactComponent as EEAImg } from '../img/services/Auslesen_img.svg';
import { ReactComponent as VerifyImg } from '../img/services/Nachkontrolle_img.svg';
import { ReactComponent as SupportImg } from '../img/services/Support_img.svg';
import { ReactComponent as ConsultingImg } from '../img/services/Consulting_img.svg';
import { ReactComponent as AnonymizeImg } from '../img/services/Anonymisieren_img.svg';
import { ReactComponent as TrainingImg } from '../img/services/Training_img.svg';
import { ReactComponent as CompanyImg } from '../img/services/Company_img.svg';
import { ReactComponent as PipelineImg } from '../img/services/Pipeline_img.svg';
// #7676FF, #79A3FF, #FB7FA7, #1FD2AB, #FF8A10

import ContactSupportIcon from '@mui/icons-material/Build';
import ConsultingIcon from '@mui/icons-material/BusinessCenter';
import EnterpriseIcon from '@mui/icons-material/Business';
import BSPlatformIcon from '@mui/icons-material/ImportantDevices'; // CoPresent';
import ModelIcon from '@mui/icons-material/BlurOn';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { WorkflowIcon } from '../routes';

const services = [
  {
    name: 'PLATFORM_BASIC',
    aka: 'BASIC',
    showInStore: false,
    type: 'PLATFORM',
    color: '#6b8fe7',
    colorHc: '#6b8fe7',
    productName: <FormattedMessage id="service.label.platform.basic" />,
    productNameKey: 'service.label.platform.basic',
    subtitleNew: <FormattedHTMLMessage id="service.subtitle.platform.basic.new" />,
    uom: <FormattedMessage id="costdashboard.processedPages" />,
    image: props => {
      return <img id="plane" src='/img/abo/paperPlane.png' style={{ height: 'auto', width: '70px' }} {...props} />;
    },
    icon: props => {
      return <BSPlatformIcon {...props} />;
    }
  },
  {
    name: 'PLATFORM_PREMIUM',
    aka: 'PREMIUM',
    showInStore: false,
    type: 'PLATFORM',
    color: '#6b8fe7',
    colorHc: '#6b8fe7',
    productName: <FormattedMessage id="service.label.platform.premium" />,
    productNameKey: 'service.label.platform.premium',
    subtitleNew: <FormattedHTMLMessage id="service.subtitle.platform.premium.new" />,
    subtitleLabelKey: 'service.subtitle.platform.premium.new.detailed',
    uom: <FormattedMessage id="costdashboard.processedPages" />,
    image: props => {
      return <img id="rocket" src='/img/abo/rocket.png' style={{ height: '50px' }} {...props} />;
    },
    icon: props => {
      return <BSPlatformIcon {...props} />;
    }
  },
  {
    name: 'SPA',
    aka: 'DSA',
    showInStore: true,
    showInModelBuilder: true,
    showInOldSubscriptionPage: true,
    type: 'AI_SERVICE',
    productName: <FormattedMessage id="service.label.spa" />,
    productNameKey: 'service.label.spa',
    subtitle: <FormattedMessage id="service.subtitle.spa" />,
    description: <FormattedMessage id="service.description.spa" />,
    subtitleNew: <FormattedMessage id="service.subtitle.spa.new" />,
    uom: <FormattedMessage id="costdashboard.processedPages" />,
    icon: props => {
      return <Splitting {...props} />;
    },
    image: props => {
      return <SplittingImg {...props} />;
    },
    color: 'var(--color-spa)',
    colorHc: 'var(--color-spa-hc)',
    getRequestCommand: 'result',
    response: {
      'v1.0': {
        'job-id': '7975e9ab19fe1a45abca0d310700180288986d3e',
        'num-pages': 5,
        batch_name: '7975e9ab19fe1a45abca0d310700180288986d3e/pdf',
        documents: {
          '7975e9ab19fe1a45abca0d310700180288986d3e_doc1': {
            firstPage: 1,
            lastPage: 2
          },
          '7975e9ab19fe1a45abca0d310700180288986d3e_doc2': {
            firstPage: 3,
            lastPage: 5
          }
        },
        pages: [
          {
            className: 'FIRST_DE',
            pageNumber: 0,
            confidence: 1.0
          },
          {
            className: 'FOLLOWUP_DE',
            pageNumber: 1,
            confidence: 0.9444812834262848
          },
          {
            className: 'FIRST_DE',
            pageNumber: 2,
            confidence: 0.7355087399482727
          },
          {
            className: 'FOLLOWUP_DE',
            pageNumber: 3,
            confidence: 0.947131484746933
          },
          {
            className: 'FOLLOWUP_DE',
            pageNumber: 4,
            confidence: 0.9413722455501556
          }
        ]
      },
      'v1.1': {
        apiVersion: 'v1.1',
        jobId: '7975e9ab19fe1a45abca0d310700180288986d3e',
        'profile-id': 'PRODUKTION',
        modelId: 'POSTEINGANG',
        modelVersion: '5.1',
        'num-pages': 5,
        batch_name: '7975e9ab19fe1a45abca0d310700180288986d3e/pdf',
        documents: {
          '7975e9ab19fe1a45abca0d310700180288986d3e_doc1': {
            firstPage: 1,
            lastPage: 2,
            downloadUrl: 'https://...'
          },
          '7975e9ab19fe1a45abca0d310700180288986d3e_doc2': {
            firstPage: 3,
            lastPage: 5,
            downloadUrl: 'https://...'
          }
        },
        pages: [
          {
            className: 'FIRST_DE',
            pageNumber: 0,
            confidence: 1.0,
            emtpy: false
          },
          {
            className: 'FOLLOWUP_DE',
            pageNumber: 1,
            confidence: 0.9444812834262848,
            emtpy: false
          },
          {
            className: 'FIRST_DE',
            pageNumber: 2,
            confidence: 0.7355087399482727,
            emtpy: false
          },
          {
            className: 'FOLLOWUP_DE',
            pageNumber: 3,
            confidence: 0.947131484746933,
            emtpy: true
          },
          {
            className: 'FOLLOWUP_DE',
            pageNumber: 4,
            confidence: 0.9413722455501556,
            emtpy: false
          }
        ]
      }
    }
  },
  {
    name: 'DCA',
    aka: 'DCA',
    showInStore: true,
    showInModelBuilder: true,
    showInOldSubscriptionPage: true,
    type: 'AI_SERVICE',
    productName: <FormattedMessage id="service.label.dca" />,
    productNameKey: 'service.label.dca',
    subtitle: <FormattedMessage id="service.subtitle.dca" />,
    description: <FormattedMessage id="service.description.dca" />,
    subtitleNew: <FormattedMessage id="service.subtitle.dca.new" />,
    uom: <FormattedMessage id="costdashboard.processedPages" />,
    icon: props => {
      return <Classify {...props} />;
    },
    image: props => {
      return <ClassifyImg {...props} />;
    },
    color: 'var(--color-dca)',
    colorHc: 'var(--color-dca-hc)',
    getRequestCommand: 'class',
    response: {
      'v1.0': {
        classificationType: 'document',
        jobId: '7975e9ab19fe1a45abca0d310700180288986d3e',
        filename: 'Avitos.pdf',
        numPages: 1,
        'doc-class': 'INVOICE_DE',
        classificationResult: {
          document: {
            class: 'INVOICE_DE',
            confidences: {
              INVOICE_DE: 0.8994424939155579,
              INVOICE_EN: 0.024921242147684097,
              CONTRACT_DE: 0.025279734283685684
            }
          }
        },
        text: '...',
        hocr: null
      },
      'v1.1': {
        apiVersion: 'v1.1',
        jobId: '7975e9ab19fe1a45abca0d310700180288986d3e',
        'profile-id': 'PRODUKTION',
        modelId: 'POSTEINGANG',
        modelVersion: '5.1',
        classificationType: 'document',
        filename: 'Avitos.pdf',
        numPages: 1,
        'doc-class': 'INVOICE_DE',
        classificationResult: {
          document: {
            class: 'INVOICE_DE',
            confidences: {
              INVOICE_DE: 0.8994424939155579,
              INVOICE_EN: 0.024921242147684097,
              CONTRACT_DE: 0.025279734283685684
            }
          }
        },
        text: '...',
        hocr: null
      }
    }
  },
  {
    name: 'EEA',
    aka: 'EEA',
    showInStore: true,
    showInModelBuilder: true,
    showInOldSubscriptionPage: true,
    type: 'AI_SERVICE',
    productName: <FormattedMessage id="service.label.eea" />,
    productNameKey: 'service.label.eea',
    description: <FormattedMessage id="service.description.eea" />,
    uom: <FormattedMessage id="costdashboard.processedPages" />,
    icon: props => {
      return <EEA {...props} />;
    },
    image: props => {
      return <EEAImg {...props} />;
    },
    subtitle: <FormattedMessage id="service.subtitle.eea" />,
    subtitleNew: <FormattedMessage id="service.subtitle.eea.new" />,
    color: 'var(--color-eea)',
    colorHc: 'var(--color-eea-hc)',
    getRequestCommand: 'entities',
    response: {
      'v1.0': {
        documentClass: 'INVOICE_DE',
        errorMsg: null,
        filename: 'Avitos.pdf',
        entities: {
          invoice_invoiceDate: [
            {
              value: '01.03.2023',
              originalValue: '01.03.2023',
              confidence: 0.9163599014282227,
              location: {
                page: 1,
                left: 0.7176280617713928,
                right: 0.7938684821128845,
                upper: 0.19298745691776276,
                lower: 0.2018243968486786
              }
            }
          ],
          invoice_invoiceGrossAmount: [
            {
              value: '911,30',
              originalValue: '911,30',
              confidence: 0.8988373279571533,
              location: {
                page: 1,
                left: 0.8975393176078796,
                right: 0.9487696886062622,
                upper: 0.6436716318130493,
                lower: 0.6550741195678711
              }
            }
          ],
          invoice_deliveryNumber: [
            {
              value: '445585',
              originalValue: '445585',
              confidence: 0.6052446961402893,
              location: {
                page: 1,
                left: 0.7176280617713928,
                right: 0.7684550285339355,
                upper: 0.2824971377849579,
                lower: 0.2913340926170349
              }
            },
            {
              value: '4500017730',
              originalValue: '4500017730',
              confidence: 0.36638930439949036,
              location: {
                page: 1,
                left: 0.7172247171401978,
                right: 0.8023396730422974,
                upper: 0.26681870222091675,
                lower: 0.2756556570529938
              }
            }
          ],

          invoice_orderNumber: []
        },
        groups: {

          taxRates: [
            {
              members: {
                invoice_taxRateGroup_netAmount: {
                  value: '765,80',
                  originalValue: '765,80',
                  confidence: 0.8731951117515564,
                  location: {
                    page: 1,
                    left: 0.9031867980957031,
                    right: 0.9491730332374573,
                    upper: 0.6000570058822632,
                    lower: 0.6100342273712158
                  }
                },
                invoice_taxRateGroup_taxAmount: {
                  value: '145,50',
                  originalValue: '145,50',
                  confidence: 0.8893721699714661,
                  location: {
                    page: 1,
                    left: 0.903993546962738,
                    right: 0.9495764374732971,
                    upper: 0.616590678691864,
                    lower: 0.6265678405761719
                  }
                },
                invoice_taxRateGroup_taxRate: {
                  value: '(19,00%)',
                  originalValue: '(19,00%)',
                  confidence: 0.8728341460227966,
                  location: {
                    page: 1,
                    left: 0.6587333679199219,
                    right: 0.7091569304466248,
                    upper: 0.6160205006599426,
                    lower: 0.625142514705658
                  }
                }
              }
            }
          ]
        },
        text: '...'
      },
      'v1.1': {
        apiVersion: 'v1.1',
        jobId: '7975e9ab19fe1a45abca0d310700180288986d3e',
        'profile-id': 'PRODUKTION',
        modelId: 'INVOICE',
        modelVersion: '5.1',
        errorMsg: null,
        filename: 'Avitos.pdf',
        entities: {
          invoice_invoiceDate: [
            {
              value: '01.März 2023',
              originalValue: '01.März 2023',
              confidence: 0.9163599014282227,
              location: {
                page: 1,
                left: 0.7176280617713928,
                right: 0.7938684821128845,
                upper: 0.19298745691776276,
                lower: 0.2018243968486786
              },
              dataType: 'DATE',
              isValid: true,
              validationErrors: [],
              normalizedValue: '2023-03-01'
            }
          ],
          invoice_invoiceGrossAmount: [
            {
              value: '911,30',
              originalValue: '911,30',
              confidence: 0.8988373279571533,
              location: {
                page: 1,
                left: 0.8975393176078796,
                right: 0.9487696886062622,
                upper: 0.6436716318130493,
                lower: 0.6550741195678711
              },
              dataType: 'AMOUNT',
              isValid: true,
              validationErrors: [],
              normalizedValue: 911.30
            }
          ],
          invoice_deliveryNumber: [
            {
              value: '445585',
              originalValue: '445585',
              confidence: 0.6052446961402893,
              location: {
                page: 1,
                left: 0.7176280617713928,
                right: 0.7684550285339355,
                upper: 0.2824971377849579,
                lower: 0.2913340926170349
              },
              dataType: 'TEXT',
              isValid: true,
              validationErrors: [],
              normalizedValue: '445585'
            },
            {
              value: '4500017730',
              originalValue: '4500017730',
              confidence: 0.36638930439949036,
              location: {
                page: 1,
                left: 0.7172247171401978,
                right: 0.8023396730422974,
                upper: 0.26681870222091675,
                lower: 0.2756556570529938
              },
              dataType: 'TEXT',
              isValid: true,
              validationErrors: [],
              normalizedValue: '4500017730'
            }
          ],

          invoice_orderNumber: []
        },
        groups: {

          taxRates: [
            {
              members: {
                invoice_taxRateGroup_netAmount: {
                  value: '765,80',
                  originalValue: '765,80',
                  confidence: 0.8731951117515564,
                  location: {
                    page: 1,
                    left: 0.9031867980957031,
                    right: 0.9491730332374573,
                    upper: 0.6000570058822632,
                    lower: 0.6100342273712158
                  },
                  dataType: 'AMOUNT',
                  isValid: true,
                  validationErrors: [],
                  normalizedValue: 765.80
                },
                invoice_taxRateGroup_taxAmount: {
                  value: '145,50',
                  originalValue: '145,50',
                  confidence: 0.8893721699714661,
                  location: {
                    page: 1,
                    left: 0.903993546962738,
                    right: 0.9495764374732971,
                    upper: 0.616590678691864,
                    lower: 0.6265678405761719
                  },
                  dataType: 'AMOUNT',
                  isValid: true,
                  validationErrors: [],
                  normalizedValue: 145.50
                },
                invoice_taxRateGroup_taxRate: {
                  value: '(19,00%)',
                  originalValue: '(19,00%)',
                  confidence: 0.8728341460227966,
                  location: {
                    page: 1,
                    left: 0.6587333679199219,
                    right: 0.7091569304466248,
                    upper: 0.6160205006599426,
                    lower: 0.625142514705658
                  },
                  dataType: 'PERCENT',
                  isValid: true,
                  validationErrors: [],
                  normalizedValue: 0.19
                }
              }
            }
          ]
        },
        dataModel: {
          invoice_invoiceDate: {
            dataType: 'DATE',
            relevance: 'STANDARD'
          },
          invoice_invoiceGrossAmount: {
            dataType: 'DECIMAL',
            relevance: 'STANDARD'
          },
          invoice_deliveryNumber: {
            dataType: 'STRING',
            relevance: 'STANDARD'
          },
          invoice_orderNumber: {
            dataType: 'STRING',
            relevance: 'STANDARD'
          }
        },
        numPages: 1,
        searchablePdfDownloadUrl: 'https://api-response....',
        text: '...'
      }
    }
  },
  {
    name: 'DAA',
    aka: 'DAA',
    showInStore: false,
    showInOldSubscriptionPage: true,
    type: 'AI_SERVICE',
    productName: <FormattedMessage id="service.label.daa" />,
    productNameKey: 'service.label.daa',
    subtitle: <FormattedMessage id="service.subtitle.daa" />,
    description: <FormattedMessage id="service.description.daa" />,
    subtitleNew: <FormattedMessage id="service.subtitle.daa.new" />,
    uom: <FormattedMessage id="costdashboard.processedPages" />,
    icon: props => {
      return <DAA {...props} />;
    },
    image: props => {
      return <AnonymizeImg {...props} />;
    },
    color: 'var(--color-daa)',
    colorHc: 'var(--color-daa-hc)',
    getRequestCommand: 'result',
    response: {
      'v1.0': {
        searchablePdfDownloadUrl: 'https://...'
      },
      'v1.1': {
        apiVersion: 'v1.1',
        jobId: 'a99725bda3a6490b9c10906f3fc845b6',
        'profile-id': 'PRODUKTION',
        searchablePdfDownloadUrl: 'https://...'
      }
    }
  },
  {
    name: 'DV',
    aka: 'DV',
    showInStore: false,
    showInOldSubscriptionPage: true,
    type: 'DV',
    productName: <FormattedMessage id="service.label.dv" />,
    productNameKey: 'service.label.dv',
    subtitle: <FormattedMessage id="service.subtitle.dv" />,
    description: <FormattedMessage id="service.description.dv" />,
    subtitleNew: <FormattedMessage id="service.subtitle.dv.new" />,
    uom: <FormattedMessage id="costdashboard.processedPages" />,
    icon: props => {
      return <Verify {...props} />;
    },
    image: props => {
      return <VerifyImg {...props} />;
    },
    color: 'var(--color-dv)',
    colorHc: 'var(--color-dv-hc)'
  },
  {
    name: 'SUPPORT',
    aka: 'SUPPORT',
    showInStore: false,
    showInOldSubscriptionPage: true,
    type: 'OPTIONAL_SERVICE',
    productName: <FormattedMessage id="service.label.support" />,
    productNameKey: 'service.label.support',
    subtitle: <FormattedMessage id="service.subtitle.support" />,
    descriptionNew: <FormattedHTMLMessage id="service.description.support.new" />,
    subtitleNew: <FormattedMessage id="service.subtitle.support.new" />,
    color: '#90A4AE',
    colorHc: '#90A4AE',
    icon: props => {
      return <ContactSupportIcon {...props} />;
    },
    image: props => {
      return <SupportImg {...props} />;
    }
  },
  {
    name: 'CONSULTING',
    aka: 'CONSULTING',
    showInStore: false,
    showInOldSubscriptionPage: true,
    type: 'OPTIONAL_SERVICE',
    productName: <FormattedMessage id="service.label.consulting" />,
    productNameKey: 'service.label.consulting',
    subtitle: <FormattedMessage id="service.subtitle.consulting" />,
    descriptionNew: <FormattedHTMLMessage id="service.description.consulting.new" />,
    subtitleNew: <FormattedMessage id="service.subtitle.consulting.new" />,
    uom: <FormattedMessage id="costdashboard.performedHours" />,
    color: '#BCAAA4',
    colorHc: '#BCAAA4',
    icon: props => {
      return <ConsultingIcon {...props} />;
    },
    image: props => {
      return <ConsultingImg {...props} />;
    }
  },
  {
    name: 'AI_MODEL',
    showInStore: false,
    type: 'AI_MODEL',
    color: '#90A4AE',
    colorHc: '#90A4AE',
    productName: <FormattedMessage id="service.label.ai.model" />,
    productNameKey: 'service.label.ai.model',
    subtitleNew: <FormattedHTMLMessage id="service.subtitle.ai.model.new" />,
    subtitleLabelKey: 'service.subtitle.ai.model.new.detailed',
    image: props => {
      return <ShoppingCartIcon {...props} style={{ width: '45px', color: '#89afcd' }} />;
    },
    icon: props => {
      return <ModelIcon {...props} />;
    }
  },
  {
    name: 'TRAINING',
    aka: 'TRAINING',
    showInStore: false,
    type: 'OPTIONAL_SERVICE',
    productName: <FormattedMessage id="service.label.training" />,
    productNameKey: 'service.label.training',
    subtitle: <FormattedMessage id="service.subtitle.training" />,
    descriptionNew: <FormattedHTMLMessage id="service.description.training.new" />,
    subtitleNew: <FormattedMessage id="service.subtitle.training.new" />,
    color: '#89bdd9',
    colorHc: '#89bdd9',
    uom: <FormattedMessage id="costdashboard.performedHours" />,
    icon: props => {
      return <HowToRegIcon {...props} />;
    },
    image: props => {
      return <TrainingImg {...props} />;
    }
  },
  {
    name: 'COMPANY_LICENSE',
    showInStore: false,
    type: 'COMPANY_LICENSE',
    color: '#426aad',
    colorHc: '#426aad',
    productName: <FormattedMessage id="service.label.companyLicense" />,
    productNameKey: 'service.label.companyLicense',
    subtitleNew: <FormattedHTMLMessage id="service.subtitle.companyLicense.new" />,
    uom: <FormattedMessage id="costdashboard.processedPages" />,
    image: props => {
      // return <img id="rocket" src='/img/abo/companyBlau.png' style={{ height: '50px' }} {...props} />;
      return <CompanyImg {...props} />;
    },
    icon: props => {
      return <EnterpriseIcon {...props} />;
    }
  },
  {
    name: 'PIPELINE',
    showInStore: false,
    type: 'PIPELINE',
    color: '#90A4AE',
    colorHc: '#90A4AE',
    productName: <FormattedMessage id="service.label.pipelines" />,
    productNameKey: 'service.label.pipelines',
    uom: <FormattedMessage id="costdashboard.processedPages" />,
    image: props => {
      return <PipelineImg {...props} />;
    },
    icon: props => {
      return <WorkflowIcon {...props} />;
    }
  }
];

export default services;

export function getService (name) {
  return services.find(
    service => (service.name === name || service.aka === name)
  );
}

export function cloneService (service) {
  const clonedService = JSON.parse(JSON.stringify(service));
  clonedService.productName = service.productName;
  clonedService.subtitle = service.subtitle;
  clonedService.description = service.description;
  clonedService.uom = service.uom;
  clonedService.icon = service.icon;
  clonedService.image = service.image;
  return clonedService;
}
